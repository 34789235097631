import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGitlab,
  faLinkedinIn,
  faGithub,
  faResearchgate,
  faReact,
  faFedora,
  faDocker, faGitAlt, faNodeJs, faSass, faStackOverflow,
} from '@fortawesome/free-brands-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Chip from '../components/chip';
import Worklist from '../components/workList';

import { Container, Row, Col } from 'reactstrap';
import {
  faChild,
  faCogs,
  faCompass, faDatabase,
  faHandHolding,
  faMobile,
  faMobileAlt,
  faUserTie,
  faPeopleCarry, faToolbox, faRocket,
} from '@fortawesome/free-solid-svg-icons'

const IndexPage = ({
  data: {
    allMarkdownRemark: {
      edges
    },
    profile: {
      childImageSharp: profileImage
    },
    codeAtlas,
    madPrint,
    gisdevio,
  }
}) => (
  <Layout>
    <SEO title="Home" keywords={[`nicokant`, `application`, `react`]} />
    <Container>
      <div className="text-center my-5">
        <Img fluid={profileImage.fixed} className="rounded-circle my-2 mx-auto" style={{ height: '200px', width: '200px' }} />
        <h2 className="m-0">Niccolò Cantù</h2>
        <div className="py-2 d-row">
          <Chip text="Full Stack Developer" /><Chip text="Mobile Developer" />
        </div>
        <p style={{ maxWidth: '25rem', margin: '1rem auto' }}>
          Hi, I'm Niccolò!<br />
          I'm a software developer freelance and consultant.<br />
          In my works I put my passion for web development and UX
        </p>

        <p style={{ maxWidth: '25rem', margin: '1rem auto' }}>
          I like to keep up with the newest technologies
          and to use my experience to help solving problems <br /> and building products
        </p>

        <h6>You can find more here</h6>
        <div>
          <a className="mx-2 text-primary" href="https://gitlab.com/users/nicokant">
            <FontAwesomeIcon icon={faGitlab} size="lg" />
          </a>
          <a className="mx-2 text-primary" href="https://stackoverflow.com/users/4374166/nicokant">
            <FontAwesomeIcon icon={faStackOverflow} /></a>
          <a className="mx-2 text-primary" href="https://www.researchgate.net/profile/Niccolo_Cantu">
            <FontAwesomeIcon icon={faResearchgate} /></a>
        </div>
      </div>

      <div className="text-center pt-5 pb-3">
        <h2 className="text-primary">I'm most experienced in</h2>
        <div className="d-flex justify-content-center p-1 mt-5 flex-wrap">
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faDatabase} size="3x" className="text-secondary" />
            <p className="mt-3">Web Applications</p>
          </div>
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faCompass} size="3x" className="text-secondary" />
            <p className="mt-3">Geographic services</p>
          </div>
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faMobileAlt} size="3x" className="text-secondary" />
            <p className="mt-3">Mobile Applications</p>
          </div>
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faCogs} size="3x" className="text-secondary" />
            <p className="mt-3">Process Automation</p>
          </div>
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faToolbox} size="3x" className="text-secondary" />
            <p className="mt-3">Administration Backoffices</p>
          </div>
        </div>
      </div>

      <div className="text-center pt-5 pb-3">
        <h2 className="text-primary">What I can offer</h2>
        <div className="d-flex justify-content-center p-1 mt-5 flex-wrap">
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faPeopleCarry} size="3x" className="text-secondary" />
            <p className="mt-1">Technical Support</p>
          </div>
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faUserTie} size="3x" className="text-secondary" />
            <p className="mt-1">Professional Consultancy</p>
          </div>
          <div className="mx-3 tile">
            <FontAwesomeIcon icon={faRocket} size="3x" className="text-secondary" />
            <p className="mt-1">Product development</p>
          </div>

        </div>
      </div>

      <div className="text-center pt-5 pb-3">
        <h2 className="text-primary">Some of the projects I worked on</h2>
        <p>You can find more in the <Link to="/works">Portfolio</Link></p>
      </div>

      <Worklist edges={edges} />

      <div className="text-center pt-5 pb-3">
        <h2 className="text-primary">Collaborations with</h2>
        <div className="d-flex flex-wrap justify-content-center align-items-center mt-4">
          <a href="https://codeatlas.it/" target="_blank" rel="noreferrer noopener"><Img fixed={codeAtlas.childImageSharp.fixed} className="mx-3" /></a>
          <a><Img fixed={madPrint.childImageSharp.fixed} className="mx-3" /></a>
          <a href="https://gisdev.io/" target="_blank" rel="noreferrer noopener"><Img fixed={gisdevio.childImageSharp.fixed} className="mx-3" /></a>
        </div>
      </div>
    </Container>
  </Layout>
);

export default IndexPage

export const pageQuery = graphql`
{
  profile: file(relativePath: { eq: "profile.jpg" }) {
    childImageSharp {
      fixed(width: 200, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  codeAtlas: file(relativePath: { eq: "code_atlas.png" }) {
    childImageSharp {
      fixed(width: 120, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  madPrint: file(relativePath: { eq: "madprint.png" }) {
    childImageSharp {
      fixed(width: 120, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  gisdevio: file(relativePath: { eq: "gisdevio.png" }) {
    childImageSharp {
      fixed(width: 120, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  allMarkdownRemark(
    sort: { order: ASC, fields: [frontmatter___startdate] }
    filter: {
      fields: { collection: { eq: "works" } }
    	frontmatter:{ featured: { eq: true } }
    }
    limit: 1000
  ) {
    edges {
      node {
        fields {
          collection
        }
        frontmatter {
          path
          title
          summary
          technologies
          featured
          startdate
          cover {
            childImageSharp{
              fixed(width: 150, height: 150) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
}
`;
