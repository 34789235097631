import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

export default function Worklist({ edges }) {
  return (
    <Row className="flex-wrap pb-5">
    {edges.map(({ node }) => (
      <Col md="6" key={node.frontmatter.path} className="mb-5 px-2">
      <Link className="work-tile" to={node.frontmatter.path}>
        <div className="d-flex">
          <div style={{ minWidth: '150px' }}>
            <Img sizes={{...node.frontmatter.cover.childImageSharp.fixed, aspectRatio: 1}} className="rounded" />
          </div>
          <div className="ml-4 d-flex flex-column justify-content-between">
            <div className="mt-1">
              <h5>{ node.frontmatter.title }</h5>
              <p>{ node.frontmatter.summary }</p>
            </div>
            <div className="text-muted small">
              {node.frontmatter.technologies.map(tech => `#${tech}`).reduce((acc, n) => `${acc} • ${n}`)}
            </div>
          </div>
        </div>
        </Link>
      </Col>
    ))}
  </Row>
  );
}
